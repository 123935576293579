import { JournalInfo, TradeItem } from 'core/types'
import { createContext, useMemo, useState } from 'react'
import AddEditNoteDialog from './dialogs/AddEditNoteDialog'
import TradeInfoDialog from './dialogs/TradeInfoDialog/TradeInfoDialog'
import { SelectJournalDialog } from './dialogs/SelectJournalDialog'
import { Modal, useModal } from 'shared/Modal'
import { ModalLayout } from 'shared/ModalLayout'
import { Button } from 'shared/Button'
import { useTradesTableApi } from './useTradesTableApi'

type TradesTableSectionContextData = {
  selectedTrade: TradeItem | null
  setSelectedTrade: React.Dispatch<React.SetStateAction<TradeItem | null>>
  openAddNoteDialog: (item?: TradeItem) => void
  openTradeInfoDialog: (item?: TradeItem) => void
  openMoveTradeToDialog: (item: TradeItem) => void
  openConfirmDeleteTradeDialog: (item: TradeItem) => void
  updateDashboard: () => void
  updateJournals: () => void
}
export const TradesTableSectionContext = createContext<TradesTableSectionContextData>({
  selectedTrade: null,
  setSelectedTrade: () => {},
  openAddNoteDialog: () => {},
  openTradeInfoDialog: () => {},
  openMoveTradeToDialog: () => Promise.resolve(void 0),
  openConfirmDeleteTradeDialog: () => {},
  updateDashboard: () => {},
  updateJournals: () => {},
})

type TradesTableSectionContextProps = {
  children: React.ReactNode
  onUpdateDashboard: () => void
  onUpdateJournals: () => void
}
export function TradesTableSectionContextProvider({
  children,
  onUpdateDashboard,
  onUpdateJournals,
}: Readonly<TradesTableSectionContextProps>) {
  const [selectedTrade, setSelectedTrade] = useState<TradeItem | null>(null)

  const { deleteTrade, isLoading, moveTrade } = useTradesTableApi()

  const addEditNoteModal = useModal()
  const confirmDeleteModal = useModal()
  const selectJournalModal = useModal()
  const tradeInfoModal = useModal()

  const providerValue = useMemo(() => {
    return {
      selectedTrade,
      setSelectedTrade,
      openAddNoteDialog: (item) => {
        item && setSelectedTrade(item)
        addEditNoteModal.open()
      },
      openTradeInfoDialog: (item) => {
        item && setSelectedTrade(item)
        tradeInfoModal.open()
      },
      openMoveTradeToDialog: (item: TradeItem) => {
        setSelectedTrade(item)
        selectJournalModal.open()
      },
      openConfirmDeleteTradeDialog: (item) => {
        setSelectedTrade(item)
        confirmDeleteModal.open()
      },
      updateDashboard: onUpdateDashboard,
      updateJournals: onUpdateJournals,
    } satisfies TradesTableSectionContextData
  }, [
    selectedTrade,
    onUpdateDashboard,
    onUpdateJournals,
    addEditNoteModal,
    tradeInfoModal,
    selectJournalModal,
    confirmDeleteModal,
  ])

  const deleteSelectedTrade = async () => {
    if (selectedTrade === null) return
    try {
      await deleteTrade(selectedTrade)
    } catch (e) {}

    onUpdateDashboard()
    onUpdateJournals()
    confirmDeleteModal.close()
  }

  const moveSelectedTrade = async (journal: JournalInfo) => {
    if (selectedTrade === null) return
    try {
      await moveTrade(selectedTrade, journal)
    } catch (e) {}
    onUpdateDashboard()
    onUpdateJournals()
    selectJournalModal.close()
  }

  return (
    <TradesTableSectionContext.Provider value={providerValue}>
      {children}
      <Modal controller={selectJournalModal}>
        <SelectJournalDialog
          trade={selectedTrade}
          onSelectJournal={(journal) => moveSelectedTrade(journal)}
          onClose={() => selectJournalModal.close()}
          isLoading={isLoading}
        />
      </Modal>
      <Modal controller={addEditNoteModal}>
        <AddEditNoteDialog onClose={() => addEditNoteModal.close()} />
      </Modal>
      <Modal controller={tradeInfoModal}>
        <TradeInfoDialog onClose={() => tradeInfoModal.close()} />
      </Modal>
      <Modal controller={confirmDeleteModal}>
        <ModalLayout>
          <ModalLayout.CloseButton onClick={() => confirmDeleteModal.close()} />
          <ModalLayout.Heading>Delete trade</ModalLayout.Heading>
          <ModalLayout.Description>
            Once removed, this action cannot be undone. The user will lose access to the trade and
            any associated data.
          </ModalLayout.Description>
          <ModalLayout.Buttons>
            <Button onClick={() => confirmDeleteModal.close()}>Cancel</Button>
            <Button color="error" isLoading={isLoading} onClick={() => deleteSelectedTrade()}>
              Delete
            </Button>
          </ModalLayout.Buttons>
        </ModalLayout>
      </Modal>
    </TradesTableSectionContext.Provider>
  )
}
