import { FormEvent, useMemo, useState } from 'react'
import './Login.scss'
import logo from 'assets/trading-journal-logo.svg'
import { LabeledDivider } from 'shared/LabeledDivider/LabeledDivider'
import { useNavigate } from 'react-router-dom'
import { API_URL, LS_ACCESS_TOKEN, LS_RESTORE_PATH } from 'core/constants'
import { HttpError, appFetch } from 'core/utils'
import { ModalLayout } from 'shared/ModalLayout'
import { Button } from 'shared/Button'
import { useUpdateJournals } from 'core/contexts/JournalsApiContext/hooks'

export function Login() {
  const [username, setUsername] = useState('')
  const usernameTrimmed = useMemo(() => username.trim(), [username])
  const [password, setPassword] = useState('')
  const passwordTrimmed = useMemo(() => password.trim(), [password])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const updateJournals = useUpdateJournals()

  const submit = async (event: FormEvent) => {
    event.preventDefault()
    if (!usernameTrimmed) return
    if (!passwordTrimmed) return
    setLoading(true)

    const handleError = (err: unknown): string => {
      if (err instanceof HttpError) {
        if (err.status === 401) return 'Wrong username or password'
        if (err.message && err.message !== '') return err.message
      }
      return 'Unknown error'
    }

    try {
      const response = await appFetch(API_URL + '/api/dashboard/login', 'POST', {
        username,
        password,
      })
      setError('')
      if ('access_token' in response) {
        localStorage.setItem(LS_ACCESS_TOKEN, response.access_token)
        const restorePath = localStorage.getItem(LS_RESTORE_PATH)
        navigate(restorePath ?? '/')
        localStorage.removeItem(LS_RESTORE_PATH)
        updateJournals()
      } else {
        throw new Error('No access token in response!')
      }
    } catch (err) {
      setError(handleError(err))
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="Login card-page">
      <ModalLayout>
        <img src={logo} alt="Logo" />
        <form onSubmit={(e) => submit(e)}>
          <ModalLayout.FormControl label="Email" className="form-control">
            <input
              type="text"
              id="login"
              placeholder="Enter email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </ModalLayout.FormControl>
          <ModalLayout.FormControl label="Password" className="form-control">
            <input
              type="password"
              id="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <a href="https://bookmap.com/support/" target="_blank" rel="noreferrer">
              <Button appearance="link" color="primary" type="button">
                Forgot password?
              </Button>
            </a>
            {error !== '' && <ModalLayout.FormControl.Error>{error}</ModalLayout.FormControl.Error>}
          </ModalLayout.FormControl>

          <Button
            color="primary"
            isLoading={loading}
            type="submit"
            disabled={usernameTrimmed === '' || passwordTrimmed === ''}
          >
            Login
          </Button>
        </form>

        <div className="alternative-section">
          <LabeledDivider label="Don't have an account?" />
          <Button onClick={() => navigate('/signup')}>Register with Bookmap credentials</Button>
        </div>
      </ModalLayout>
    </div>
  )
}
