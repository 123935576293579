import classNames from 'classnames'
import calendarIco from 'assets/icons/calendar-event.svg'
import { Button } from 'shared/Button'

type DateInputTriggerProps = {
  open: boolean
  value: Date
  dateFormatter: Intl.DateTimeFormat
  compactTimeFormatter: Intl.DateTimeFormat
}
export function DateInputTrigger({
  open,
  value,
  dateFormatter,
  compactTimeFormatter,
}: Readonly<DateInputTriggerProps>) {
  return (
    <Button
      appearance="input-trigger"
      className={classNames({
        DateInput__trigger: true,
        DateInput__trigger__activated: open,
      })}
    >
      <img className="DateInput__trigger__icon" src={calendarIco} alt="Calendar" />
      <span className="DateInput__trigger__value text-normal-regular">
        <span>{dateFormatter.format(value)}</span>
        <div className="divider">|</div>
        <span>{compactTimeFormatter.format(value)}</span>
      </span>
    </Button>
  )
}
