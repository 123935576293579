import './TradeInfoDialog.scss'
import { FlatCard } from 'shared/FlatCard/FlatCard'
import ModalHeader from 'shared/ModalHeader/ModalHeader'
import TextDate from 'shared/TextDate/TextDate'
import TextMoney from 'shared/TextMoney/TextMoney'
import { useContext } from 'react'
import { durationToHumanReadable } from 'core/utils'
import { TradesTableSectionContext } from '../../TradesTableSectionContext'
import { Button } from 'shared/Button'
import Chip from 'shared/Chip'

type TradeInfoDialogProps = {
  onClose: () => void
}
function TradeInfoDialog({ onClose }: Readonly<TradeInfoDialogProps>) {
  const { selectedTrade } = useContext(TradesTableSectionContext)

  if (selectedTrade === null)
    return (
      <div className="TradeInfoDialog">
        <FlatCard>Something went wrong...</FlatCard>
      </div>
    )

  const dateEntry = new Date(selectedTrade.timeEntry ?? '')
  const dateExit = new Date(selectedTrade.timeExit ?? '')

  return (
    <div className="TradeInfoDialog">
      <FlatCard>
        <ModalHeader label="Trade info" onClose={() => onClose()} />

        <div className="content">
          <div className="info-grid">
            <div className="cell">
              <div className="label">Entry date & time</div>
              <div className="value">
                {selectedTrade.timeEntry && dateEntry ? <TextDate date={dateEntry} /> : 'N/A'}
              </div>
            </div>
            <div className="cell">
              <div className="label">Exit date & time</div>
              <div className="value">
                {selectedTrade.timeExit && dateExit ? <TextDate date={dateExit} /> : 'N/A'}
              </div>
            </div>
            <div className="cell">
              <div className="label">Time in trade</div>
              <div className="value">
                {selectedTrade.timeEntry && selectedTrade.timeExit
                  ? durationToHumanReadable(
                      new Date(selectedTrade.timeEntry),
                      new Date(selectedTrade.timeExit)
                    )
                  : 'N/A'}
              </div>
            </div>

            <div className="cell">
              <div className="label">Instrument</div>
              <div className="value">{selectedTrade.instrumentAlias}</div>
            </div>
            <div className="cell">
              <div className="label">Max size</div>
              <div className="value">{selectedTrade.maxSize}</div>
            </div>
            <div className="cell">
              <div className="label">Commissions</div>
              <div className="value">
                <TextMoney value={selectedTrade.commission ?? 0} />
              </div>
            </div>

            <div className="cell">
              <div className="label">Entry price</div>
              <div className="value">
                {' '}
                <TextMoney value={selectedTrade.priceEntry} />
              </div>
            </div>
            <div className="cell">
              <div className="label">Exit price</div>
              <div className="value">
                <TextMoney value={selectedTrade.priceExit} />
              </div>
            </div>
            <div className="cell">
              <div className="label">Profit</div>
              <div className="value">
                {' '}
                <TextMoney value={selectedTrade.profit} colored />
              </div>
            </div>

            <div className="cell">
              <div className="label">Type</div>
              <div className="value">
                {selectedTrade.isBuy ? (
                  <Chip className="green">Buy</Chip>
                ) : (
                  <Chip className="red">Sell</Chip>
                )}
              </div>
            </div>
            <div className="cell">
              <div className="label">MFE</div>
              <div className="value">{selectedTrade.mfe}</div>
            </div>
            <div className="cell">
              <div className="label">MAE</div>
              <div className="value">{selectedTrade.mae}</div>
            </div>
          </div>

          <div className="note">
            <div className="label">Note</div>
            <div className="value">
              {selectedTrade.tradeNote ? selectedTrade.tradeNote.noteText : '-'}
            </div>
          </div>

          <div className="buttons">
            <Button onClick={() => onClose()}>Ok</Button>
          </div>
        </div>
      </FlatCard>
    </div>
  )
}

export default TradeInfoDialog
