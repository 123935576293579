import { TABLE_PAGE_SIZE } from 'core/constants'
import { JournalInfo, TradeItem, TradesResponse } from 'core/types'
import './TradesTable.scss'
import img3DotsVertical from 'assets/icons/dots-vertical.svg'

import Table from 'shared/Table'
import TextDate from 'shared/TextDate/TextDate'
import TextMoney from 'shared/TextMoney/TextMoney'
import { useContext, useMemo } from 'react'
import { TradesTableSectionContext } from '../TradesTableSectionContext'
import Chip from 'shared/Chip'
import TableCell from 'shared/Table/components/TableCell'
import Menu from 'shared/Menu'
import { FilterContext } from 'pages/DashboardPage/FilterContext'
import { Button } from 'shared/Button'
import { useJournals } from 'core/api/journals'
import { useUserInfo } from 'core/api/common'
import { useSelectedJournal } from '../../Filters/useSelectedJournal'

type TradesTableProps = { data: TradesResponse }
export function TradesTable({ data }: Readonly<TradesTableProps>) {
  const {
    openAddNoteDialog,
    openTradeInfoDialog,
    openMoveTradeToDialog,
    openConfirmDeleteTradeDialog,
  } = useContext(TradesTableSectionContext)

  const { filters } = useContext(FilterContext)
  const journals = useJournals()
  const journalsIdMap: Record<string, JournalInfo> = useMemo(() => {
    if (!journals.data) return {}
    return Object.fromEntries(journals.data.map((j) => [j.id, j]))
  }, [journals])

  const isEmpty = data?.pageInfo.totalElements === 0
  const userInfo = useUserInfo()
  const selectedJournal = useSelectedJournal()

  const showTradesActionColumn =
    selectedJournal === null || userInfo.data?.userEmail === selectedJournal?.owner.userEmail

  return (
    <Table className="TradesTable" tableNeighbor={isEmpty && <EmptyState />}>
      <Table.Header>
        {filters.journal === null && <Table.HeaderCell>Journal</Table.HeaderCell>}
        <Table.HeaderCell>Entry date</Table.HeaderCell>
        <Table.HeaderCell>Exit date</Table.HeaderCell>
        <Table.HeaderCell>Instrument</Table.HeaderCell>
        <Table.HeaderCell>Max size</Table.HeaderCell>
        <Table.HeaderCell>Entry price</Table.HeaderCell>
        <Table.HeaderCell>Exit price</Table.HeaderCell>
        <Table.HeaderCell>Profit</Table.HeaderCell>
        <Table.HeaderCell>Type</Table.HeaderCell>
        <Table.HeaderCell tooltipText="Maximum Favorable Excursion">MFE</Table.HeaderCell>
        <Table.HeaderCell tooltipText="Maximum Adverse Excursion">MAE</Table.HeaderCell>
        <Table.HeaderCell>Note</Table.HeaderCell>
        {showTradesActionColumn && <Table.HeaderCell.Empty />}
      </Table.Header>
      <Table.Body>
        {!isEmpty &&
          data.result.map((item) => (
            <Table.Row key={item.id} onClick={() => openTradeInfoDialog(item)}>
              {filters.journal === null && (
                <Table.Cell className="no-wrap">
                  {journalsIdMap[item.journalId].journalName ?? '-'}
                </Table.Cell>
              )}

              <Table.Cell>
                {item.timeEntry ? <TextDate date={new Date(item.timeEntry)} /> : '-'}
              </Table.Cell>
              <Table.Cell>
                {item.timeExit ? <TextDate date={new Date(item.timeExit)} /> : '-'}
              </Table.Cell>
              <Table.Cell className="bold">{item.instrumentAlias}</Table.Cell>
              <Table.Cell className="bold">{item.maxSize}</Table.Cell>
              <Table.Cell className="bold">
                <TextMoney value={item.priceEntry} />
              </Table.Cell>
              <Table.Cell className="bold">
                <TextMoney value={item.priceExit} />
              </Table.Cell>
              <Table.Cell className="bold">
                <TextMoney value={item.profit} colored />
              </Table.Cell>
              <Table.Cell>
                {item.isBuy ? (
                  <Chip className="bg-green">Buy</Chip>
                ) : (
                  <Chip className="bg-red">Sell</Chip>
                )}
              </Table.Cell>
              <Table.Cell className="bold">{item.mfe}</Table.Cell>
              <Table.Cell className="bold">{item.mae}</Table.Cell>
              <NoteCell noteItem={item.tradeNote} />

              {showTradesActionColumn && (
                <TableCell.Menu>
                  <div className="center">
                    <Menu
                      trigger={
                        <Button appearance="text" size="small" square>
                          <img src={img3DotsVertical} alt="Edit" />
                        </Button>
                      }
                    >
                      {item.tradeNote === null || item.tradeNote.noteText === '' ? (
                        <Menu.Item onClick={() => openAddNoteDialog(item)}>Add note</Menu.Item>
                      ) : (
                        <Menu.Item onClick={() => openAddNoteDialog(item)}>Edit note</Menu.Item>
                      )}
                      {/* <Menu.Item>Clear note</Menu.Item> */}
                      {item.isLocked === false && (
                        <>
                          <Menu.Divider />
                          <Menu.Item onClick={() => openMoveTradeToDialog(item)}>
                            Move to...
                          </Menu.Item>
                          <Menu.Item
                            className="red"
                            onClick={() => openConfirmDeleteTradeDialog(item)}
                          >
                            Delete trade
                          </Menu.Item>
                        </>
                      )}
                    </Menu>
                  </div>
                </TableCell.Menu>
              )}
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  )
}

type NoteCellProps = { noteItem: TradeItem['tradeNote']; onOpenAddNoteDialog?: () => void }
function NoteCell({ noteItem, onOpenAddNoteDialog }: Readonly<NoteCellProps>) {
  return (
    <Table.Cell className="NoteCell">
      {noteItem === null || noteItem.noteText === '' ? (
        <div className="text empty"> - </div>
      ) : (
        <abbr title={noteItem.noteText} className="text">
          {noteItem.noteText}
        </abbr>
      )}
      {/* <button className="text-button" onClick={() => onOpenAddNoteDialog()}>
        {noteItem === null || noteItem.noteText === '' ? (
          <div className="text empty">Add note...</div>
        ) : (
          <abbr title={noteItem.noteText} className="text">
            {noteItem.noteText}
          </abbr>
        )}
        <img src={imgPencil} alt="Edit" />
      </button> */}
    </Table.Cell>
  )
}

function EmptyState() {
  return (
    <div className="EmptyState" style={{ height: 48 * TABLE_PAGE_SIZE + 'px' }}>
      <h2>No trades</h2>
      <p>
        It looks like there are no results that match your search criteria. Please, try another one
      </p>
    </div>
  )
}
