import { ButtonAlignment, ButtonAppearance, ButtonColor, ButtonSize } from './models'
import './Button.scss'
import useButtonNameCalculated from './useButtonClassNameCalculated'
import { forwardRef } from 'react'
import { Tooltip } from 'shared/Tooltip'

export type ButtonProps = {
  children?: React.ReactNode
  isLoading?: boolean
  appearance?: ButtonAppearance
  color?: ButtonColor
  size?: ButtonSize
  alignment?: ButtonAlignment
  square?: boolean
  tooltipContent?: React.ReactNode
  tooltipDelay?: number
  fontType?: 'button' | 'text'
} & React.ButtonHTMLAttributes<HTMLButtonElement>
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      isLoading,
      className,
      appearance,
      color,
      alignment,
      size,
      square,
      tooltipContent,
      tooltipDelay,
      fontType,
      ...props
    }: Readonly<ButtonProps>,
    ref
  ) => {
    const classNamesComputed = useButtonNameCalculated({
      appearance,
      color,
      size,
      className,
      square,
      alignment,
      fontType,
    })
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (isLoading) return
      props.onClick?.(e)
    }

    if (tooltipContent) {
      return (
        <Tooltip
          tooltipContent={tooltipContent}
          delay={tooltipDelay ?? 200}
          className="ButtonTooltip"
        >
          <button ref={ref} className={classNamesComputed} onClick={handleClick} {...props}>
            {isLoading === undefined ? (
              children
            ) : (
              <div className={'content ' + (isLoading && 'loading')}>
                <div className="children">{children}</div>
                <div className="loader">
                  <div className="loader-ball"></div>
                </div>
              </div>
            )}
          </button>
        </Tooltip>
      )
    }

    return (
      <button ref={ref} className={classNamesComputed} onClick={handleClick} {...props}>
        {isLoading === undefined ? (
          children
        ) : (
          <div className={'content ' + (isLoading && 'loading')}>
            <div className="children">{children}</div>
            <div className="loader">
              <div className="loader-ball"></div>
            </div>
          </div>
        )}
      </button>
    )
  }
)

export default Button
