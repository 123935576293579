import './TradesTableSection.scss'
import { Pagination } from './Pagination/Pagination'
import { Card } from 'shared/Card/Card'
import { TradesTable } from './TradesTable/TradesTable'
import { TradesTableSectionContextProvider } from './TradesTableSectionContext'
import { useDashboardApi } from '../../../DashboardApiContext'
import { useJournalsApi } from 'core/contexts/JournalsApiContext/JournalsApiContext'

export function TradesTableSection() {
  const {
    trades: { data, isLoading },
    setPaginationPage,
    paginationPage,
    mutateAll,
  } = useDashboardApi()
  const { updateJournals } = useJournalsApi()

  if (paginationPage !== 0 && data && data.result.length === 0) setPaginationPage(0)

  if (!data || isLoading) return <Card className="TradesTableSection loading"></Card>

  return (
    <TradesTableSectionContextProvider
      onUpdateDashboard={mutateAll}
      onUpdateJournals={updateJournals}
    >
      <Card className="TradesTableSection">
        <div className="header text-normal-regular">Trade history</div>
        <TradesTable data={data} />
        <Pagination
          pagesTotal={data?.pageInfo.totalPages ?? 0}
          currentPage={paginationPage}
          requestPage={(v) => setPaginationPage(v)}
        />
      </Card>
    </TradesTableSectionContextProvider>
  )
}
