import { JournalInfo, TradeItem } from 'core/types'
import './SelectJournalDialog.scss'
import { SimpleDropdown } from 'shared/Dropdown'
import { DropdownItem } from 'shared/Dropdown/components/DropdownItem'
import { useState } from 'react'
import { ModalLayout } from 'shared/ModalLayout'
import { Button } from 'shared/Button'
import { useJournals } from 'core/api/journals'

type SelectJournalDialogProps = {
  onSelectJournal: (journal: JournalInfo) => void
  onClose: () => void
  isLoading: boolean
  trade?: TradeItem | null
}
export function SelectJournalDialog({
  isLoading,
  onSelectJournal,
  onClose,
  trade,
}: Readonly<SelectJournalDialogProps>) {
  const journals = useJournals()
  const [value, setValue] = useState<JournalInfo | undefined>(undefined)

  const journalsFiltered = journals.data?.filter((j) => trade && trade.journalId !== j.id) ?? []

  const buttonHandlerCancel = () => {
    setValue(void 0)
    onClose()
  }
  const buttonHandlerMove = () => {
    if (value) onSelectJournal(value)
  }

  return (
    <ModalLayout className="SelectJournalDialog modal">
      <ModalLayout.Heading>Move trade to...</ModalLayout.Heading>

      <form>
        <ModalLayout.FormControl label="Target journal">
          <SimpleDropdown
            className="input"
            value={value}
            getVisualValue={(j) => j.journalName}
            onChange={(v) => setValue(v)}
            placeholder="Select journal"
          >
            {journalsFiltered.map((journal) => (
              <DropdownItem value={journal} key={journal.id}>
                {journal.journalName}
              </DropdownItem>
            ))}
          </SimpleDropdown>
        </ModalLayout.FormControl>
      </form>

      <ModalLayout.Buttons>
        <Button onClick={buttonHandlerCancel}>Cancel</Button>
        <Button color="primary" onClick={buttonHandlerMove} isLoading={isLoading}>
          Move
        </Button>
      </ModalLayout.Buttons>

      {/* <div className="modal-buttons">
        <button
          className="solid-button"
          onClick={() => {
            setValue(void 0)
            onClose()
          }}
        >
          Cancel
        </button>
        <button disabled={!value} className="accent-button" onClick={() => value && onClose(value)}>
          Move
        </button>
      </div> */}
    </ModalLayout>
  )
}
