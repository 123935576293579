import { LS_ACCESS_TOKEN } from './constants'

export const toFixedInteger = (number: number, toFixedLength = 2): string => {
  const result = number.toString()
  return '0'.repeat(Math.abs(result.length - toFixedLength)) + result
}

export const formatMoney = (value: number, { valueSym = '$' } = {}): string => {
  const signSym = value >= 0 ? '' : '-'
  if (Math.abs(value) < 1e-2 && Math.abs(value) > 0) return signSym + valueSym + value.toFixed(4)
  const [big, small] = Math.abs(value).toFixed(2).split('.')

  return signSym + valueSym + addCommas(big) + '.' + small

  function addCommas(value: string): string {
    if (value.length < 3) return value
    return value
      .split('')
      .map((v, i, arr) => ((arr.length - i) % 3 === 0 && i !== 0 ? ',' + v : v))
      .join('')
  }
}

export const repeat = (cb: () => unknown, repeatTimes: number) => {
  for (let i = 0; i < repeatTimes; i++) cb()
}

export class HttpError extends Error {
  constructor(message: string, public status: number, public body: any) {
    super(message)
  }
}

export const appFetch = async <T = any>(
  url: URL | string,
  method: string,
  body?: Record<string, any> | string | string[]
): Promise<T> => {
  const token = localStorage.getItem(LS_ACCESS_TOKEN)
  try {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      ...(body && { body: JSON.stringify(body) }),
    })

    let parsedJson: any = null
    try {
      parsedJson = await response.json()
    } catch (err) {}
    const result = { body: parsedJson, response }
    if (!result.response.ok) throw new HttpError('HTTP error', result.response.status, result.body)
    return result.body
  } catch (err) {
    if (err instanceof HttpError) {
      console.error('Http error: ', err.body)
    } else {
      console.error('Unknown error: ', err)
    }
    throw err
  }
}

export function durationToHumanReadable(
  dateFrom: Date,
  dateTo: Date,
  { separator = ' ' } = {}
): string {
  const [timestampFrom, timestampTo] = [
    Math.floor(dateFrom.getTime() / 1000),
    Math.floor(dateTo.getTime() / 1000),
  ]
  const delta = timestampTo - timestampFrom
  if (delta <= 0) return '0s'
  const units = [
    { label: 'd', value: 1 * 60 * 60 * 24 },
    { label: 'h', value: 1 * 60 * 60 },
    { label: 'm', value: 1 * 60 },
    { label: 's', value: 1 },
  ]

  let remainingTime = Math.abs(delta)
  const result = []

  for (const unit of units) {
    const quotient = Math.floor(remainingTime / unit.value)
    if (quotient > 0) {
      result.push(`${quotient}${unit.label}`)
      remainingTime -= quotient * unit.value
    }
  }

  return result.join(separator)
}

export const INITIAL_SWR_DATA = {
  data: undefined,
  error: undefined,
  isValidating: false,
  mutate: () => Promise.resolve() as Promise<undefined>,
  isLoading: true,
}

/** Reduce number of elements in array in most simple way */
export function reduceArrayTo<T>(
  /** Target array */
  arr: T[],
  /** Number of elements should be reduced to */
  targetLength: number
): T[] {
  if (arr.length < targetLength) return arr

  const result = []
  const ratio = arr.length / targetLength
  for (let i = 0; i < targetLength; i++) result.push(arr[Math.floor(i * ratio)])

  /* Last element in result should match last element on input*/
  result[result.length - 1] = arr[arr.length - 1]
  return result
}
