import { createPortal } from 'react-dom'
import { ModalController } from './models'
import './Modal.scss'
import { useRef } from 'react'

type ModalProps<T> = { children: React.ReactNode; controller: ModalController<T> }
export const Modal = <T,>({ children, controller }: ModalProps<T>) => {
  const overlayRef = useRef<HTMLDivElement>(null)
  if (!controller.isOpen) return null

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!overlayRef.current) return
    if (e.target !== overlayRef.current) return
    controller.onBackdropClick()
  }
  return createPortal(
    <div ref={overlayRef} className="Modal__overlay" onClick={handleClick}>
      <div className="Modal">{children}</div>
    </div>,
    document.body
  )
}
